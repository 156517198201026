import React, { createContext, useReducer, ReactNode } from "react";

interface State {
  language: string;
  user: any;
  portalLoaded: boolean;
  loggedIn: boolean;
}

interface Action {
  type: string;
  language?: string;
  user?: any;
  tokens?: any;
}

const defaultLanguage = process.env.GATSBY_DEFAULT_LANGUAGE;

export const GlobalStateContext = createContext<State | undefined>(undefined);
export const GlobalDispatchContext = createContext<React.Dispatch<Action> | undefined>(undefined);

const initialState: State = {
  language: defaultLanguage || "en",
  user: {},
  portalLoaded: false,
  loggedIn: false,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "portalLoaded": {
      return {
        ...state,
        portalLoaded: true,
      };
    }


    case "storeUser": {
      return {
        ...state,
        user: action.user,
      };
    }

    case "loggedIn": {
      localStorage.setItem("tokens", JSON.stringify(action.tokens));
      return {
        ...state,
        loggedIn: true,
        portalLoaded: true,
      };
    }

    case "logout": {
      localStorage.removeItem("tokens");
      return {
        ...state,
        loggedIn: false,
        user: {},
      };
    }

    case "setLanguage": {
      return {
        ...state,
        language: action.language!,
      };
    }

    default:
      throw new Error("Bad Action Type");
  }
}

const GlobalContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};


export default GlobalContextProvider;
